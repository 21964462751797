import React, { useState, useContext, useEffect, createRef } from 'react';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import { ISelect } from 'components/form-brics/select/js/intrfaces';
import { PopupWrapperContext } from 'components/popup/js/popup-wrapper';
import { popupDefaultProps } from 'components/popup/js/popup-config';
import { FB_CB } from 'components/form-brics/select/js/helpers';

export const CustomSelect = ({
    onCustomChange = FB_CB,
    onBlur = FB_CB,
    id = 'select',
    invalid,
    options,
    defaultValue = '',
    placeholder,
    name,
    prefix,
    required,
    disabled,
    className,
    isNav,
    selectClassName,
}: ISelect): JSX.Element => {
    const { close, isActive, onShow } = useContext(PopupWrapperContext);
    const ref = createRef<HTMLUListElement>();
    const [activeValue, setValue] = useState(defaultValue);
    const onClick = (value = '') => {
        close();
        setValue(value);
        onCustomChange(value);
    };
    useEffect(() => {
        let entered = '';
        let timer = 0;
        const onKeyPress = (e: globalThis.KeyboardEvent) => {
            entered += e.key;
            try {
                ref.current?.querySelector<HTMLButtonElement>(`[data-value^=${entered}]`)?.focus();
            } catch (_) {
                clearTimeout(timer);
                entered = '';
            }
            if (timer) clearTimeout(timer);
            timer = window.setTimeout(() => {
                entered = '';
            }, 500);
        };
        if (isActive) window.addEventListener('keypress', onKeyPress);
        return () => {
            window.removeEventListener('keypress', onKeyPress);
        };
    }, [isActive]);
    return (
        <Tippy
            {...popupDefaultProps}
            duration={[250, 0]}
            offset={[0, 0]}
            maxWidth="100%"
            disabled={disabled}
            placement="bottom-start"
            onShow={onShow}
            onHide={() => {
                close();
                setTimeout(onBlur, 50);
            }}
            content={
                <ul
                    className={'select-list'}
                    aria-label={placeholder}
                    role="listbox"
                    aria-roledescription="select options"
                    aria-labelledby={'lbl' + id}
                    aria-describedby={invalid ? 'err' + prefix + name : undefined}
                    id={id}
                    ref={ref}
                >
                    {options.map(({ value, text }, index) => (
                        <li key={id + index} className={'select-list__item'}>
                            <button
                                data-value={text.toLowerCase()}
                                className={'select-list__btn'}
                                type="button"
                                onClick={() => onClick(value)}
                                role="option"
                            >
                                {text}
                            </button>
                        </li>
                    ))}
                </ul>
            }
        >
            <button
                aria-haspopup="dialog"
                disabled={disabled}
                aria-roledescription="select"
                aria-invalid={invalid}
                aria-required={required}
                aria-describedby={invalid ? 'err' + prefix + name : undefined}
                className={classnames(className, selectClassName, 'select input', {
                    'is-active': isActive,
                    'is-error': invalid,
                    'is-nav': isNav,
                })}
                type="button"
            >
                {options.find(({ value }) => value === activeValue)?.text || placeholder || name}
            </button>
        </Tippy>
    );
};
