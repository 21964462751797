import React, { ReactElement, useMemo } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

const LazyImage = ({ src, alt, className, contain }: iLazyImage): ReactElement => {
    const id = useMemo(() => nanoid(10), []);

    return (
        <img
            key={id}
            className={classNames(
                className,
                'lazyload',
                { 'of-contain': contain },
                { 'of-cover': !contain }
            )}
            loading="lazy"
            data-srcset={src}
            data-sizes="auto"
            alt={alt}
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
    );
};

interface iLazyImage {
    src: string;
    loading?: string;
    alt?: string | undefined;
    className?: string | undefined;
    contain?: boolean;
}

export { LazyImage };
