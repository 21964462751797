import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

export const Container = ({ children, className, isGaped, ...props }: IContainer): JSX.Element => {
    return (
        <div
            className={classNames(className, 'container', { 'container--gaped': isGaped })}
            {...props}
        >
            {children}
        </div>
    );
};

interface IContainer extends HTMLAttributes<HTMLDivElement> {
    isGaped?: boolean;
}
