import React from 'react';
import { ProjectSelect } from 'components/form-brics/select/js/project-select';
import { ISelectOption } from 'components/form-brics/select/js/intrfaces';
import { scrollService } from 'general/js/scroll-service';
import { normalizeString } from 'general/js/normalize-string';

const AnchorNav = ({ items, caption }: iAnchorNavigation) => {
    const options: ISelectOption[] = items.map(({ name, id }) => ({ value: id, text: name }));
    const onChange = (value?: string) => {
        if (!value) return;
        const item = items.find(({ id }) => id === value);
        if (item) {
            scrollService.scrollToElementsTop(item.element);
            window.location.hash = normalizeString(item.name);
        }
    };
    return (
        <ProjectSelect
            name="anchorlist"
            placeholder={caption}
            options={options}
            onCustomChange={onChange}
            selectClassName="input--mobile-small"
            isNav
        />
    );
};

interface AnchorItem {
    element: HTMLElement;
    name: string;
    id: string;
}

interface iAnchorNavigation {
    items: AnchorItem[];
    caption: string;
}

export { AnchorNav, AnchorItem };
