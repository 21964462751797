import { DcBaseComponent } from '@deleteagency/dc';

const UPLOADED_FILE_NAMES_CLASS_NAME = 'input-file-upload-text';

class FormControlsComponent extends DcBaseComponent<HTMLDivElement> {
    static namespace = 'form-controls';

    init() {
        const forms: NodeListOf<HTMLFormElement> = this.element.querySelectorAll('form');

        forms.forEach((form) => {
            this.addListener(form, 'reset', () => {
                const uploadedFileNamesBoxes: NodeListOf<HTMLDivElement> = form.querySelectorAll(
                    `.${UPLOADED_FILE_NAMES_CLASS_NAME}`
                );

                uploadedFileNamesBoxes.forEach((el) => {
                    el.textContent = '';
                });
            });
        });

        const uploadFileInputs: NodeListOf<HTMLInputElement> =
            this.element.querySelectorAll('input[type="file"]');

        uploadFileInputs.forEach((input) => {
            const uploadedFileNamesBox = document.createElement('div');

            uploadedFileNamesBox.classList.add(UPLOADED_FILE_NAMES_CLASS_NAME);
            input.after(uploadedFileNamesBox);

            this.addListener(input, 'change', () => {
                if (!input.files) return;

                const files = Array.from(input.files);

                const textParts = files.map((file) => file.name);
                uploadedFileNamesBox.textContent =
                    textParts.length > 0 ? textParts.join(', ') : 'No file chosen';
            });
        });
    }
}

export default FormControlsComponent;
