import React, { useContext } from 'react';
import { ProjectSelect } from 'components/form-brics/select/js/project-select';
import { IFilterField } from 'general/types/api-types';
import { FilterContext } from 'components/filter/js/filter-service';

export const FilterSelect = ({
    name,
    options,
    placeholder,
    defaultValue,
}: IFilterField): JSX.Element => {
    const { setFilter, isLoading } = useContext(FilterContext);
    return (
        <ProjectSelect
            options={options.map((item) => ({ text: item.caption, value: item.value }))}
            name={name}
            disabled={isLoading}
            placeholder={placeholder}
            defaultValue={defaultValue}
            selectClassName="input--mobile-small"
            onCustomChange={(t) => {
                setFilter({ [name]: t });
            }}
        />
    );
};
