import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { SvgIcon } from 'general/js/svg-icon';
import { IArticleCard } from 'general/types/api-types';

export const ArticleCard = ({
    link,
    className,
    summary,
    imgSrcset,
    title,
    type,
    children,
    themeName,
}: IArticleCardComponent): JSX.Element => {
    return (
        <a
            href={link}
            className={classnames(className, 'article-card', { [`theme-${themeName}`]: themeName })}
        >
            <div className="article-card__media">
                <img className="lazyload" data-srcset={imgSrcset} alt={title} />
                {type && (
                    <div className="article-card__icon-wrap">
                        <SvgIcon iconName={type} size={[20]} />
                    </div>
                )}
            </div>
            <div className="article-card__content">
                <h3
                    className="article-card__title"
                    dangerouslySetInnerHTML={{ __html: title || '' }}
                />
                <p className="article-card__summary">{summary}</p>
                {children}
            </div>
        </a>
    );
};

interface IArticleCardComponent extends IArticleCard {
    className?: string;
    children?: ReactNode;
}
