import React, { useRef, useEffect, useState, HTMLAttributes } from 'react';
import { TypicalVideoProps } from './video-creator';

const VideoHTML5 = ({
    url,
    isActive,
    controls = true,
    onReady,
    mute = false,
    type,
    ...props
}: TypicalVideoProps & HTMLAttributes<HTMLVideoElement>): JSX.Element => {
    const [isLoaded, setLoading] = useState(false);
    const ref = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        if (isActive && isLoaded && ref?.current) {
            ref.current.play();
        } else if (!isActive && isLoaded && ref?.current) {
            ref.current.pause();
        }
    }, [isActive, isLoaded]);
    const onLoad = () => {
        setLoading(true);
        if (onReady) onReady();
    };
    return (
        <video
            controls={controls}
            ref={ref}
            playsInline
            onLoadedData={onLoad}
            muted={mute}
            {...props}
            autoPlay
            loop
        >
            <source src={url} />
        </video>
    );
};

export { VideoHTML5 };
