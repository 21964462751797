import React, { HTMLAttributes, useState } from 'react';
import classnames from 'classnames';
import { LazyImage } from 'general/js/lazy-image';
import { IBasicVideoSettings, VideoCreator } from 'components/video/js/video-creator';
import { Spinner } from 'components/spinner/js/spinner';

export const Video = ({ className, thumbnailSrcSet, ...props }: IVideo): JSX.Element => {
    const [isPlay, setPlay] = useState(false);
    const [isLoading, setLoading] = useState(false);

    return (
        <div className={classnames(className, 'video')} {...props}>
            {thumbnailSrcSet && (
                <LazyImage
                    src={thumbnailSrcSet}
                    className={classnames('video__media', isPlay ? 'is-play' : 'is-pause')}
                />
            )}
            {!isPlay && (
                <>
                    <button
                        type="button"
                        className="video__fullbleed-btn"
                        onClick={() => {
                            setLoading(true);
                            setPlay(true);
                        }}
                    >
                        <span className="visually-hidden">play video</span>
                    </button>
                    <div className="btn-round btn-round--white video__fake-btn">
                        <span>{isPlay ? 'Paused' : 'Play'}</span>
                    </div>
                </>
            )}
            {isLoading && <Spinner isActive={true} />}
            {isPlay && (
                <VideoCreator
                    className="video__element"
                    isActive={true}
                    onReady={() => setLoading(false)}
                    {...props}
                />
            )}
        </div>
    );
};

type IVideo = IBasicVideoSettings & HTMLAttributes<HTMLDivElement>;
