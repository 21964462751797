import React from 'react';
import classnames from 'classnames';
import { FilterSelect } from 'components/filter/js/filter-select';
import { IFilterRow } from 'components/filter/js/types';

const FilterRow = ({ items, alignLeft }: IFilterRow): JSX.Element | null => {
    const _items = items.filter((field) => field.type !== 'hidden');
    if (!_items.length) return null;
    return (
        <ul className={classnames('filters-row', { 'filters-row--left': alignLeft })}>
            {_items.map((field, index) => (
                <li key={'ff_' + index} className="filters-row__item">
                    <FilterSelect {...field} />
                </li>
            ))}
        </ul>
    );
};

export { FilterRow };
