import React from 'react';
import { BookmarksFilterService } from 'components/bookmarks/js/bookmarks-filter-service';
import { Modal } from 'components/modal/js/modal';
import { unmountModal } from 'components/modal/js/render-container';
import { BookmarksCardsGrid } from 'components/bookmarks/js/bookmarks-cards-grid';
import { Container } from 'components/container/js/container';

export const BookmarksModal = (): JSX.Element => {
    return (
        <Modal closeCb={unmountModal} modifiers={['bookmarks']}>
            <Container className="filters-batch bookmarks-batch">
                <h2 className="bookmarks-batch__title">
                    My <em>bookmarks</em>
                </h2>
                <BookmarksFilterService>
                    <BookmarksCardsGrid />
                </BookmarksFilterService>
            </Container>
        </Modal>
    );
};
