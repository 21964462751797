import React, { ChangeEvent, useState, useEffect } from 'react';
import classnames from 'classnames';
import { FB_CB } from 'components/form-brics/select/js/helpers';
import { ISelect } from 'components/form-brics/select/js/intrfaces';

export const NativeSelect = ({
    invalid,
    options,
    id = '',
    name,
    onCustomChange = FB_CB,
    onBlur = FB_CB,
    defaultValue = '',
    placeholder,
    value,
    required,
    disabled,
    className,
    selectClassName,
    isNav,
}: ISelect): JSX.Element => {
    const [_value, setValue] = useState(defaultValue);
    useEffect(() => {
        if (value !== undefined) setValue(value);
    }, [value]);
    return (
        <label className={classnames(className, { 'is-nav': isNav })}>
            <select
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setValue(e.currentTarget.value);
                    onCustomChange(e.currentTarget.value);
                }}
                disabled={disabled}
                onBlur={onBlur}
                value={_value}
                aria-invalid={invalid}
                aria-describedby={invalid ? 'err' + name : undefined}
                aria-required={required}
                className={classnames(
                    'select input',
                    { 'is-error': invalid, 'is-nav': isNav },
                    selectClassName
                )}
                required={required}
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                {options.map((option, index) => (
                    <option key={id + name + index} value={option.value}>
                        {option.text}
                    </option>
                ))}
            </select>
        </label>
    );
};
