import React from 'react';
import classNames from 'classnames';
import { IPredictiveSearch } from 'components/search/js/types';

export const PredictiveSearch = ({
    searchLink,
    items,
    className,
}: IPredictiveSearch): JSX.Element | null => {
    if (items.length === 0) {
        return null;
    }

    return (
        <div className={classNames('predictive-search', className)}>
            <ul className="predictive-search__list">
                {items.map(({ text }) => (
                    <li key={`predictive-search-${text}`} className="predictive-search__item">
                        <a
                            className="predictive-search__link"
                            href={`${searchLink}?search=${text}`}
                        >
                            {text}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
