import React, { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { removeEmptyKeys } from 'general/js/remove-emty-keys';
import { AvailableOptions, IFilterServiceState } from 'components/filter/js/types';
import { FilterContext } from 'components/filter/js/filter-service';
import { bookmarksStore } from 'components/bookmarks/js/bookmarks-store';

export const BookmarksFilterService = ({
    children,
    initialFilters = {},
}: IBookmarksFilterService): JSX.Element => {
    const storeItems = useStore(bookmarksStore).items;
    const [filterState, setState] = useState<IFilterServiceState>({
        isFailedLoading: false,
        isLoading: false,
        appliedFilters: initialFilters,
        items: storeItems,
        pagination: {
            totalPages: 1,
            currentPage: 0,
            totalElements: 0,
            elementsOnCurrentPage: 0,
        },
    });
    const { appliedFilters, items, pagination } = filterState;
    const filterBy = (_options: AvailableOptions): void => {
        const newFilters = { ...appliedFilters, ..._options };
        removeEmptyKeys(newFilters);
        setState({
            ...filterState,
            items: storeItems
                .filter((item) => {
                    if (!newFilters.date || newFilters.date === 'all') return true;
                    if (!item.date) return false;
                    const itemDate = +new Date(item.date);
                    const filterDate = +new Date(newFilters.date as string);
                    return filterDate === itemDate;
                })
                .filter((item) => {
                    if (!newFilters.type || newFilters.type === 'all') return true;
                    if (!item.type) return false;
                    return item.type === newFilters.type;
                })
                .filter((item) => {
                    if (!newFilters.region || newFilters.region === 'all') return true;
                    if (!item.region) return false;
                    return item.region === newFilters.region;
                }),
            appliedFilters: newFilters,
        });
    };
    const setFilter = (_options: AvailableOptions): void => {
        filterBy(_options);
    };
    const clearFilters = (): void => {
        const droppedFilter: AvailableOptions = {};
        Object.keys(initialFilters).forEach((key) => {
            droppedFilter[key] = '';
        });
        filterBy(droppedFilter);
    };

    useEffect(() => filterBy(appliedFilters), [storeItems]);
    return (
        <FilterContext.Provider
            value={{
                isLoading: false,
                isFailedLoading: false,
                items,
                appliedFilters,
                pagination,
                setFilter,
                clearFilters,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export interface IBookmarksFilterService {
    children: ReactNode;
    initialFilters?: AvailableOptions;
}
