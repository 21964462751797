import React from 'react';
import { removeBookmark } from 'components/bookmarks/js/bookmarks-store';

export const RemoveBookmarkBtn = ({ id, title }: IRemoveBookmarkBtn): JSX.Element => {
    const onClick = () => removeBookmark(id);
    return (
        <button className="article-card-remove" type="button" onClick={onClick}>
            Remove
            <span className="visually-hidden">{`'${title}' from bookmarks`}</span>
        </button>
    );
};

interface IRemoveBookmarkBtn {
    id: string;
    title?: string;
}
