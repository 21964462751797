import React, { useContext, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { FilterContext } from 'components/filter/js/filter-service';
import { NoResult } from 'components/filter/js/no-result';
import { ArticleCardsGrid } from 'components/article-cards-grid/js/article-cards-grid';

export const BookmarksCardsGrid = ({
    className,
    ...props
}: HTMLAttributes<HTMLElement>): JSX.Element => {
    const { items } = useContext(FilterContext);
    if (!items.length)
        return (
            <NoResult className={className} text="You haven't added anything here yet" {...props} />
        );
    return (
        <div className={classnames(className, 'filter-cards-grid')}>
            <ArticleCardsGrid className="filter-cards-grid__items" items={items} insideBookmarks />
        </div>
    );
};
