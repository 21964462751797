import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { Modal } from 'components/modal/js/modal';
import { IBurgerLink } from 'components/burger-menu/js/types';
import { unmountModal } from 'components/modal/js/render-container';
import { Container } from 'components/container/js/container';
import classNames from 'classnames';
import { ShareBurgerList } from 'components/burger-menu/js/share-burger-list';

export const BurgerMenu = ({ items, sharedLink }: IBurgerMenu): JSX.Element => {
    const id = useMemo(() => nanoid(10), []);
    return (
        <Modal closeCb={unmountModal} modifiers={['burger']} id="burger">
            <Container className="burger-menu">
                <ul className="burger-menu__list">
                    {items.map(({ text, url, target, isPrimary, items }, index) => (
                        <li key={`${id}_${index}`} className="burger-menu__item">
                            <a
                                className={classNames('burger-menu__main-link', {
                                    'burger-menu__main-link--small': !isPrimary,
                                })}
                                href={url}
                                target={target}
                            >
                                {text}
                            </a>
                            {items && (
                                <ul className="burger-menu__sublist">
                                    {items.map((subitem, subindex) => (
                                        <li
                                            key={`${id}_${index}_${subindex}`}
                                            className="burger-menu__sub-item"
                                        >
                                            <a
                                                className="burger-menu__sub-link"
                                                href={url}
                                                target={subitem.target}
                                            >
                                                {subitem.text}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <ShareBurgerList className="burger-menu__share" sharedLink={sharedLink} />
            </Container>
        </Modal>
    );
};

interface IBurgerMenu {
    items: IBurgerLink[];
    sharedLink?: string;
}
