import { DcBaseComponent } from '@deleteagency/dc';
import { nanoid } from 'nanoid';
import { deviceObserver } from 'general/js/device-observer';
import { FB_CB } from 'components/form-brics/select/js/helpers';

class IframeSizerComponent extends DcBaseComponent<HTMLDivElement, void, void> {
    static namespace = 'iframe-sizer';

    private id = nanoid(10);

    private iframe: HTMLIFrameElement | null = null;

    private unsubscribe: CallableFunction = FB_CB;

    init() {
        this.iframe = this.element.querySelector('iframe');

        if (this.iframe) {
            window.addEventListener('message', this.onResponseFromIframe);
            this.unsubscribe = deviceObserver.subscribeOnResize(this.requestHeight);
            this.requestHeight();
            this.iframe.addEventListener('load', () => {
                setTimeout(this.requestHeight, 50);
            });
        }
    }

    requestHeight = () => {
        if (this.iframe) {
            const matching = this.iframe.src.match(/^((http[s]?|ftp):\/)?\/?([^:\/\s]+)/);
            const origin = matching?.at(0) || '';
            this.iframe.contentWindow?.postMessage(`GetBodyHeight_${this.id}`, origin);
        }
    };

    onResponseFromIframe = (event: MessageEvent) => {
        if (this.iframe && typeof event.data === 'string') {
            const messageparts = event.data.split('_');
            if (messageparts[0] === 'Height' && messageparts[1] === this.id) {
                this.element.classList.add('iframe-component--custom-sized');
                this.setIframeSize(messageparts[2]);
            }
        }
    };

    setIframeSize = (height: string) => {
        if (this.iframe) {
            this.iframe.setAttribute('height', `${height}px`);
            this.iframe.style.height = height + 'px';
        }
    };

    onDestroy() {
        if (this.iframe) window.removeEventListener('message', this.onResponseFromIframe);
        this.unsubscribe();
    }
}

export default IframeSizerComponent;
