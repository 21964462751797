import React, { HTMLAttributes } from 'react';
import { ShareList } from 'components/share/js/share-list';
import { IShareName } from 'components/share/js/types';
import { GenerateShareItemsByNames } from 'components/share/js/generate-share-items-by-names';
import classNames from 'classnames';

const shareNames: IShareName[] = ['twitter', 'facebook', 'linkedin', 'copyLink'];

export const ShareBurgerList = ({
    className,
    sharedLink,
    ...props
}: IShareBurgerList & HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const _sharedLink = sharedLink || window.location.origin;
    const shareItems = GenerateShareItemsByNames({
        sharedLink: _sharedLink,
        shareNames,
    });

    return (
        <div className={classNames('share-burger', className)} {...props}>
            <div className="share-burger__title">Share:</div>
            <ShareList
                shareItems={shareItems}
                sharedLink={_sharedLink}
                baseClassName="share-burger-list"
                contentType="text"
            />
        </div>
    );
};

interface IShareBurgerList {
    sharedLink?: string;
}
