import { toast } from 'react-toastify';

const windowOpen = (url: string) => {
    window.open(url);
};

export const twitterShare = (url: string) => {
    windowOpen('http://twitter.com/share?url=' + encodeURIComponent(url));
};

export const copyToClipboard = (url: string) => {
    window.navigator.clipboard.writeText(url);
    toast('Copied to clipboard!', { type: 'success' });
};

export const linkedinShare = (url: string) => {
    windowOpen('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url));
};

export const facebookShare = (url: string) => {
    windowOpen('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url));
};
