import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { createElement } from 'react';
import { Video } from 'components/video/js/video';
import { IBasicVideoSettings } from 'components/video/js/video-creator';

class VideoComponent extends DcBaseComponent<HTMLDivElement, IBasicVideoSettings> {
    static namespace = 'video-section';

    init() {
        const options = this.options;
        const box = this.element.querySelector('.video');
        box?.classList.remove('video');

        render(createElement(Video, { ...options }), box);
    }
}

export default VideoComponent;
