import DcExpandGlideSlider, { ISliderRefs } from 'components/slider/js/dc-expand-glide-slider';
// import whatInput from 'what-input';

interface IRefs extends ISliderRefs {
    content: HTMLDivElement;
}

class ISliderComponent extends DcExpandGlideSlider<HTMLDivElement, void, IRefs> {
    static namespace = 'i-slider';

    static requiredRefs = ['navs', 'cards', 'content'];

    animTime = 0;

    timerId: NodeJS.Timer = setInterval(() => {});

    init() {
        const autoPlayTime = Number(this.refs.content.getAttribute('data-autoplay-time'));
        this.animTime = autoPlayTime || 4000;
        if (autoPlayTime) {
            const animation: number = autoPlayTime / 1000 || 4;
            this.refs.content.style.setProperty('--autoplaytime', `${animation}s`);
        }
        this.glideOptions.animationDuration = 100;
        this.glideOptions.rewindDuration = 100;
        this.glideOptions.rewind = true;
        this.glideOptions.touchRatio = 3;
        this.sliderInit();
        this.manageISlider();
    }

    getVideo = (index: number): HTMLVideoElement | null => {
        return this.cards[index].querySelector('.i-slider-media video');
    };

    goNext = (slider: { go: (arg0: string) => void; }) => {
        let i = 0;

        this.timerId = setInterval(() => {
            i++;
            if (i === this.animTime / 1000) {
                clearInterval(this.timerId);
                slider.go('>');
            }
        }, 1000);
    };

    manageISlider = () => {
        const { slider, navs } = this;
        slider.on(['run.start'], () => {
            clearInterval(this.timerId);
            navs.forEach((bullet) => bullet.classList.remove('is-watched'));
        });

        slider.on(['move.after', 'swipe.move'], () => {
            navs.forEach((bullet, index) => {
                if (index < slider.index) {
                    bullet.classList.add('is-watched');
                } else {
                    bullet.classList.remove('is-watched');
                }
            });

            clearInterval(this.timerId);
            const video = this.getVideo(slider.index);
            this.animTime = Number(this.cards[slider.index].getAttribute('data-slide-time'));
            this.refs.content.style.setProperty('--autoplaytime', `${this.animTime / 1000}s`);

            if (video) {
                video.currentTime = 0;
                video.play();
                this.goNext(slider);
            } else {
                this.refs.content.style.setProperty('--autoplaytime', `${this.animTime / 1000}s`);
                this.goNext(slider);
            }
        });
    };
}

export default ISliderComponent;
