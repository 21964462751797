import { createElement } from 'react';
import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { ShareBtn } from 'components/share-btn/js/share-btn';
import { IShareBtn } from 'components/share-btn/js/types';

class ShareComponent extends DcBaseComponent<HTMLDivElement, IShareBtn> {
    static namespace = 'share-btn';

    init() {
        render(createElement(ShareBtn, { ...this.options }, null), this.element);
        const shareBtnClassNames = this.element.className
            .split(' ')
            .filter((className) => className.startsWith('share-btn'));
        this.element.classList.remove(...shareBtnClassNames);
    }
}

export default ShareComponent;
