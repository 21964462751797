import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'general/js/svg-icon';

export const ButtonNextArrow = ({
    className,
    type = 'button',
    ...props
}: IButtonArrow): JSX.Element => {
    return (
        <button type={type} {...props} className={classNames(className, 'btn-arrow')}>
            <SvgIcon iconName="arrow-right" size={[16]} />
        </button>
    );
};
type IButtonArrow = ButtonHTMLAttributes<HTMLButtonElement> & {
    type?: keyof JSX.IntrinsicElements;
    button?: boolean;
};
