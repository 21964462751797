/* general styles */
import 'general/scss/index.scss';

/* plugins */
import 'general/js/what-input';

/* components */
import 'components/lazysizes';
import 'components/_footer';
import 'components/modal';
import 'components/section';
import 'components/burger-menu';
import 'components/header';
import 'components/quote';
import 'components/article-layout';
import 'components/richtext';
import 'components/search';
import 'components/hero';
import 'components/hero-overlay';
import 'components/person-card';
import 'components/generic-card';
import 'components/generic-slider';
import 'components/video';
import 'components/team-slider';
import 'components/article-info';
import 'components/article-aside';
import 'components/author';
import 'components/bookmarks';
import 'components/form-brics/input';
import 'components/form-brics/checkbox';
import 'components/form-brics/select';
import 'components/form';
import 'components/section-form';
import 'components/anchor-nav';
import 'components/article-tags';
import 'components/article-related-services';
import 'components/article-btns';
import 'components/section-menu';
import 'components/section-search';
import 'components/article-card';
import 'components/article-cards-grid';
import 'components/article';
import 'components/share-btn';
import 'components/spinner';
import 'components/component-title';
import 'components/filter';
import 'components/text-media';
import 'components/text-media-slider';
import 'components/iframe';
import 'components/recomended-cards';
import 'components/media-stat';
import 'components/insta-slider';

/* require svg */
// @ts-ignore
const files = require.context('general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
