import React from 'react';
import classnames from 'classnames';
import { INoResult } from 'components/filter/js/types';

export const NoResult = ({ className, title, text, ...props }: INoResult): JSX.Element => {
    return (
        <div className={classnames(className, 'no-result')} {...props}>
            <h3 className="no-result__title">{title || 'No results'}</h3>
            <p className="no-result__description">
                {text || 'Unfortunately, we do not have results for your request'}
            </p>
        </div>
    );
};
