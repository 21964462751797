import React, { useContext, HTMLAttributes } from 'react';
import { FilterContext } from 'components/filter/js/filter-service';
import { ButtonNextArrow } from 'components/buttons/js/button-next-arrow';
import classnames from 'classnames';

const COUNT_OF_PAGES_IN_ROW = 5;

export const FilterPagination = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
    const {
        pagination: { totalPages, currentPage },
        isLoading,
        setFilter,
    } = useContext(FilterContext);

    if (totalPages <= 1) return null;

    let startIndex = currentPage - Math.floor(COUNT_OF_PAGES_IN_ROW / 2);
    startIndex = startIndex < 0 ? 0 : startIndex;
    const endIndex = startIndex + COUNT_OF_PAGES_IN_ROW;
    const pageNumbers = [...Array(totalPages).keys()].slice(startIndex, endIndex);

    const onClick = (page: number) => {
        setFilter({ page });
    };

    const backIsDisabled = isLoading || currentPage <= 0;
    const nextIsDisabled = isLoading || currentPage >= totalPages - 1;
    return (
        <nav
            className={classnames(className, 'filters-pagination')}
            role="navigation"
            aria-roledescription="pagination"
            {...props}
        >
            <ButtonNextArrow
                className="filters-pagination__btn-back"
                disabled={backIsDisabled}
                aria-disabled={backIsDisabled}
                title="go to previous page"
                onClick={!backIsDisabled ? () => onClick(currentPage - 1) : undefined}
            />
            <ul className="filters-pagination__pages">
                {pageNumbers.map((page, index) => (
                    <li key={`${page}_${index}`}>
                        <button
                            className={classnames('filters-pagination__page', {
                                'is-active': currentPage === page,
                            })}
                            onClick={!isLoading ? () => onClick(page) : undefined}
                        >
                            <span className="visually-hidden">go to page with number</span>
                            <span>{page + 1}</span>
                        </button>
                    </li>
                ))}
            </ul>
            <ButtonNextArrow
                className="filters-pagination__btn-next"
                disabled={nextIsDisabled}
                aria-disabled={nextIsDisabled}
                onClick={!nextIsDisabled ? () => onClick(currentPage + 1) : undefined}
                title="go to next page"
            />
        </nav>
    );
};
