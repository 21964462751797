import { createStore, createEvent } from 'effector';
import { IArticleCard } from 'general/types/api-types';

interface IStore {
    items: IArticleCard[];
}

const KEY_FOR_LS = 'kfwr-bookmarks';

export const addBookmark = createEvent<IArticleCard>();
export const removeBookmark = createEvent<string>();

const initialItems: IArticleCard[] = JSON.parse(global.localStorage?.getItem(KEY_FOR_LS) || '[]');

const setItemsToLocalStorage = (items: IArticleCard[]) => {
    global.localStorage.setItem(KEY_FOR_LS, JSON.stringify(items));
    return { items };
};

export const bookmarksStore = createStore<IStore>({ items: initialItems })
    .on(addBookmark, ({ items }, item) => {
        const result = [...items, item];
        return setItemsToLocalStorage(result);
    })
    .on(removeBookmark, ({ items }, idToRemove) => {
        const result = items.filter(({ id }) => id !== idToRemove);
        return setItemsToLocalStorage(result);
    });
