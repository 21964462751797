import React, { useContext } from 'react';
import classnames from 'classnames';
import { FilterContext } from 'components/filter/js/filter-service';
import { NoResult } from 'components/filter/js/no-result';
import { Spinner } from 'components/spinner/js/spinner';
import { ArticleCardsGrid } from 'components/article-cards-grid/js/article-cards-grid';
import { IFiltersCardsGrid } from 'components/filter/js/types';

export const FiltersCardsGrid = ({
    className,
    noResultsTitle,
    noResultsMessage,
    ...props
}: IFiltersCardsGrid): JSX.Element => {
    const { isLoading, items } = useContext(FilterContext);
    if (!isLoading && !items.length) {
        return (
            <NoResult
                title={noResultsTitle}
                text={noResultsMessage}
                className={className}
                {...props}
            />
        );
    }
    return (
        <div className={classnames(className, 'filter-cards-grid', { 'is-loading': isLoading })}>
            {isLoading && <Spinner isActive />}
            <ArticleCardsGrid className="filter-cards-grid__items" items={items} />
        </div>
    );
};
