import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { createElement } from 'react';
import { renderContainer } from 'components/modal/js/render-container';
import { BookmarksModal } from 'components/bookmarks/js/bookmarks-modal';

const DAYOPTIONS: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
};

export const dateFormat = new Intl.DateTimeFormat('en-GB', DAYOPTIONS).format;

class BookmarksModalComponent extends DcBaseComponent<HTMLDivElement, void, void> {
    static namespace = 'bookmarks-modal';

    init() {
        this.addListener(this.element, 'click', this.onClick);
    }

    onClick = () => {
        render(createElement(BookmarksModal, null, null), renderContainer);
    };
}

export default BookmarksModalComponent;
