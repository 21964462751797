import { createStore, createEvent } from 'effector';
import whatInput from 'what-input';
import { deviceObserver, operators } from 'general/js/device-observer';
import { deviceNames } from 'config';

const isItATouch = () =>
    deviceObserver!.is(operators.less, deviceNames.tablet) ||
    (whatInput.ask() === 'touch' && deviceObserver!.is(operators.less, deviceNames.desktopWide));

const setTouch = createEvent<boolean>();

if (!isItATouch()) setTouch(false);
whatInput.registerOnChange(() => setTouch(isItATouch()));
deviceObserver?.subscribeOnChange(() => setTouch(isItATouch()));

//we have to prevent hydrate error on first render, cause on ssr we know nothing about user
export const isTouchStore = createStore<boolean>(true).on(setTouch, (_, is) => is);
