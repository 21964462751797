import DcExpandGlideSlider from 'components/slider/js/dc-expand-glide-slider';

class TextMediaSliderComponent extends DcExpandGlideSlider<HTMLDivElement, void, void> {
    static namespace = 'text-media-slider';

    init() {
        this.glideOptions.perView = 1;
        this.glideOptions.animationDuration = 300;
        this.glideOptions.rewind = false;
        this.glideOptions.touchRatio = 2;
        this.sliderInit();
        this.goToSlide();
    }

    goToSlide = () => {
        const { slider, cards } = this;
        const searchString = new URL(window.location.href);
        const slideID = searchString.hash.replace(/[#]/g, '');
        if (slideID) {
            cards.forEach((card, index) => {
                if (card.getAttribute('id') === slideID) {
                    slider.go(`=${index}`);
                }
            });
        }
    };
}

export default TextMediaSliderComponent;
