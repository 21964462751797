import React, { ReactElement, useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { createPortal } from 'react-dom';
import { SvgIcon } from 'general/js/svg-icon';
import { pageLocker } from 'general/js/page-locker';
import classNames from 'classnames';
import { deviceObserver } from 'general/js/device-observer';
import { dynamicStylesService } from 'general/js/dynamic-styles-service';

const BLOCK = 'modal';

const Modal = ({
    hasOverlay = true,
    hasDefaultClose = true,
    children,
    closeCb,
    modifiers,
    className,
    id = 'modal',
}: iModal): ReactElement => {
    const [hasScroll, setHasScroll] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onKeydown = (e: KeyboardEvent) => {
            if (e.code === 'Escape') closeCb();
        };
        window.addEventListener('keydown', onKeydown);
        pageLocker.lock(id);
        const checkOnScroll = () => {
            if (contentRef.current) {
                setHasScroll(contentRef.current.scrollHeight > contentRef.current.clientHeight);
            }
        };
        const unsubscribe = deviceObserver.subscribeOnResize(checkOnScroll);
        dynamicStylesService.setStyles({
            id: 'body-scroll-gap',
            css: 'padding-right: var(--scrollbar);',
            selector: '.body',
        });
        checkOnScroll();
        return () => {
            dynamicStylesService.remove('body-scroll-gap');
            unsubscribe();
            window.removeEventListener('keydown', onKeydown);
            pageLocker.unlock(id);
        };
    }, []);
    return createPortal(
        <FocusTrap active={true} focusTrapOptions={{ allowOutsideClick: true }}>
            <div
                className={classNames(
                    BLOCK,
                    modifiers?.map((mod) => `${BLOCK}--${mod}`)
                )}
            >
                {hasOverlay && <div className={`${BLOCK}__overlay`} onClick={closeCb} />}
                <div
                    ref={contentRef}
                    className={classNames('modal__content', className, {
                        'is-scrollable': hasScroll,
                    })}
                >
                    {children}
                </div>
                {hasDefaultClose && (
                    <button
                        className={`${BLOCK}__close btn-square`}
                        type="button"
                        onClick={closeCb}
                        aria-label="close modal"
                    >
                        <SvgIcon iconName="minus" size={[40]} />
                    </button>
                )}
            </div>
        </FocusTrap>,
        document.body
    );
};

type AvailableTypes = ReactElement | null | false;

interface iModal {
    id?: string;
    hasOverlay?: boolean;
    hasDefaultClose?: boolean;
    closeCb: () => void;
    modifiers?: string[];
    className?: string;
    children: AvailableTypes | AvailableTypes[];
}

export { Modal };
