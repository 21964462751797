import React from 'react';
import { FilterService } from 'components/filter/js/filter-service';
import { FilterRow } from 'components/filter/js/filter-row';
import { FiltersCardsGrid } from 'components/filter/js/filters-cards-grid';
import { FilterPagination } from 'components/filter/js/filter-pagination';
import { ISimpleFilterBatch } from 'components/filter/js/types';

export const SimpleFilterBatch = ({
    service,
    filtersCardsGrid,
    filterRow,
}: ISimpleFilterBatch): JSX.Element => {
    return (
        <div className="filters-batch">
            <FilterService {...service}>
                <FilterRow {...filterRow} />
                <FiltersCardsGrid {...filtersCardsGrid} />
                <FilterPagination />
            </FilterService>
        </div>
    );
};
