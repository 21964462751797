import DcExpandGlideSlider from 'components/slider/js/dc-expand-glide-slider';

class GenericSliderComponent extends DcExpandGlideSlider<HTMLDivElement, void, void> {
    static namespace = 'generic-slider';

    init() {
        this.glideOptions.gap = 0;
        this.sliderInit();
    }
}

export default GenericSliderComponent;
