import { IShareItem, IShareResovler } from 'components/share/js/types';

// TODO: downloadLink can be undefined and sharedLink, temporary solution default empty string
export const GenerateShareItemsByNames = ({
    downloadLink = '',
    sharedLink = '',
    embedText = '',
    emailLink = '',
    shareNames,
}: IShareResovler): IShareItem[] => {
    const shareItemsWithLinks = shareNames.map((name) => {
        const link =
            (name === 'downloadLink' && downloadLink) ||
            (name === 'embed' && embedText) ||
            (name === 'email' && emailLink) ||
            sharedLink;
        return { name, link };
    });

    return shareItemsWithLinks;
};
