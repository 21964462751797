import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
import { IArticleCard, IArticleType } from 'general/types/api-types';
import { IThemeName } from 'general/types/themes';
import {
    addBookmark,
    bookmarksStore,
    removeBookmark,
} from 'components/bookmarks/js/bookmarks-store';
import { Subscription } from 'effector';

interface IRefs extends IDCRefsCollection {
    saveBtn: HTMLButtonElement;
    date: HTMLDivElement;
    title: HTMLHeadElement;
    summary: HTMLDivElement;
    img: HTMLImageElement;
}

export interface IAddBookmarkOptions {
    articleId: string;
    articleType: IArticleType;
    articleRegion: string;
}

const ACTIVE_CLASS = 'is-active';

class AddBookmarkComponent extends DcBaseComponent<HTMLDivElement, IAddBookmarkOptions, IRefs> {
    static namespace = 'add-bookmark';

    private isActive = false;

    private subscription: Subscription | undefined;

    init() {
        const { items } = bookmarksStore.getState();
        this.isActive = items.some(({ id }) => id === this.options.articleId);
        if (this.isActive) this.refs.saveBtn.classList.add(ACTIVE_CLASS);
        this.addListener(this.refs.saveBtn, 'click', this.onClick);
        this.subscription = removeBookmark.watch((id) => {
            if (id === this.options.articleId) {
                this.isActive = false;
                this.refs.saveBtn.classList.remove(ACTIVE_CLASS);
                this.refs.saveBtn.setAttribute('aria-label', 'add to bookmarks');
            }
        });
    }

    private onClick = () => {
        if (this.isActive) {
            removeBookmark(this.options.articleId);
        } else {
            this.addBookmarkToStore();
        }
    };

    private addBookmarkToStore = () => {
        this.isActive = true;
        this.refs.saveBtn.classList.add(ACTIVE_CLASS);
        this.refs.saveBtn.setAttribute('aria-label', 'remove from bookmarks');
        const itemToAdd: IArticleCard = {
            id: this.options.articleId,
            type: this.options.articleType,
            date: this.refs.date.innerText.trim(),
            title: this.refs.title.innerText.trim(),
            summary: this.refs.summary.innerText.trim(),
            region: this.options.articleRegion || '',
            imgSrcset:
                this.refs.img.getAttribute('data-srcset') ||
                this.refs.img.getAttribute('srcset') ||
                this.refs.img.getAttribute('data-src') ||
                this.refs.img.getAttribute('src') ||
                '',
            link: window.location.href,
            themeName:
                (document.body.className
                    .split(' ')
                    .find((className) => className.match('theme-'))
                    ?.replace('theme-', '') as IThemeName | null) || 'royal-blue',
        };
        addBookmark(itemToAdd);
    };

    protected onDestroy() {
        this.subscription?.unsubscribe();
    }
}

export default AddBookmarkComponent;
