import { createElement } from 'react';
import { render } from 'react-dom';
import { DcBaseComponent } from '@deleteagency/dc';
import { IFilterComponentInizialization } from 'general/types/api-types';
import { SimpleFilterBatch } from 'components/filter/js/simple-filter-batch';
import { ISimpleFilterBatch, AvailableOptions } from 'components/filter/js/types';

class Filter extends DcBaseComponent<HTMLDivElement, IFilterComponentInizialization, void> {
    static namespace = 'filter';

    init() {
        const data = this.options;
        if (data) {
            const props: ISimpleFilterBatch = {
                filterRow: {
                    items: data.filters,
                },
                service: {
                    initialFilters: data.filters.reduce<AvailableOptions>(
                        (res, item) => ({
                            ...res,
                            [item.name]: item.defaultValue,
                        }),
                        {}
                    ),
                    endpoint: data.endpoint,
                    initialItems: data.initialItems,
                    initialPagination: data.pagination,
                },
                filtersCardsGrid: {
                    noResultsTitle: data.noResultsTitle,
                    noResultsMessage: data.noResultsMessage,
                },
            };
            render(createElement(SimpleFilterBatch, props), this.element);
        } else {
            console.warn(
                'Something went wrong, we can not render filters with this options ',
                this.element
            );
        }
    }
}

export default Filter;
