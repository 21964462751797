/**
 * Updates glide movement with width of additional clones width.
 *
 * @param  {Object} Glide
 * @param  {Object} Components
 * @return {Object}
 */
export default function grow(Glide, Components) {
    return {
        /**
         * Adds to the passed translate width of the half of clones.
         *
         * @param  {Number} translate
         * @return {Number}
         */
        modify(translate) {
            return translate + Components.Clones.grow / 2;
        },
    };
}
