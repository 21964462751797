import './scss/index.scss';

import { dcFactory } from '@deleteagency/dc';
import AddBookmarkComponent from './js/add-bookmark.component';
import BookmarksModalComponent from './js/bookmarks-modal.component';

// @ts-ignore
dcFactory.register(AddBookmarkComponent);
// @ts-ignore
dcFactory.register(BookmarksModalComponent);
