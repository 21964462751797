import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
import { IBurgerLink } from 'components/burger-menu/js/types';
import { operators } from '@deleteagency/device-observer/src/index';
import { deviceObserver } from 'general/js/device-observer';

export interface IOptions {
    items?: IBurgerLink[];
}

interface IRefs extends IDCRefsCollection {
    triggers: HTMLElement[];
    contents: HTMLElement[];
}

class FooterNavComponent extends DcBaseComponent<HTMLDivElement, IOptions, IRefs> {
    static namespace = 'footer-nav';

    static requiredRefs = ['triggers', 'contents'];

    _isActiveNum = 0;

    _isDesktop = true;

    init() {
        this._isDesktop = deviceObserver.is(operators.moreOrEqual, 'desktop');

        this.refs.triggers[this._isActiveNum].classList.add('is-active');
        this.refs.contents[this._isActiveNum].classList.add('is-visible');

        deviceObserver.subscribeOnChange(() => {
            this._isDesktop = deviceObserver.is(operators.moreOrEqual, 'desktop');
            this._checkActiveNavs();
        });

        this.refs.triggers.forEach((element, index) => {
            this.addListener(element, 'click', () => this._onClick(index, this._isDesktop));
        });
    }

    _checkActiveNavs = () => {
        const activeAmount = this.refs.contents.filter((element) =>
            element.classList.contains('is-visible')
        ).length;

        if ((activeAmount === 0 || activeAmount > 1) && this._isDesktop) {
            this._clearAllExceptFirst();
        }
    };

    _onClick = (key: number, desktop: boolean) => {
        if (desktop) {
            if (key !== this._isActiveNum) {
                this._isActiveNum = key;
                this.hide();
                this.open(key);
            }
        } else {
            this.toggleElem(key);
        }
    };

    toggleElem = (key: number) => {
        if (this.refs.triggers[key].classList.contains('is-active')) {
            this.refs.contents[key].classList.remove('is-visible');
            this.refs.triggers[key].classList.remove('is-active');
        } else {
            this.refs.contents[key].classList.add('is-visible');
            this.refs.triggers[key].classList.add('is-active');
        }
    };

    open = (key: number) => {
        this.refs.contents[key].classList.add('is-visible');
        this.refs.triggers[key].classList.add('is-active');
    };

    hide = () => {
        this.refs.contents.forEach((element, index) => {
            if (index !== this._isActiveNum) {
                element.classList.remove('is-visible');
                this.refs.triggers[index].classList.remove('is-active');
            }
        });
    };

    _clearAllExceptFirst = () => {
        this.refs.contents.forEach((element) => {
            element.classList.remove('is-visible');
        });
        this.refs.triggers.forEach((element) => {
            element.classList.remove('is-active');
        });
        this.refs.contents[0].classList.add('is-visible');
        this.refs.triggers[0].classList.add('is-active');
    };
}

export default FooterNavComponent;
