import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useStore } from 'effector-react';
import { isTouchStore } from 'general/js/touch-switcher';
import { ISelect } from 'components/form-brics/select/js/intrfaces';
import { NativeSelect } from 'components/form-brics/select/js/native-select';
import { PopupWrapper } from 'components/popup/js/popup-wrapper';
import { CustomSelect } from 'components/form-brics/select/js/custom-select';

export const ProjectSelect = (props: ISelect): JSX.Element => {
    const id = useMemo(() => nanoid(10), []);
    const isTouch = useStore(isTouchStore);
    return isTouch ? (
        <NativeSelect {...props} />
    ) : (
        <PopupWrapper id={id}>
            <CustomSelect id={id} {...props} />
        </PopupWrapper>
    );
};
