import './scss/index.scss';

import FormRangeComponent from 'components/form/js/form-range.component';
import FormControlsComponent from 'components/form/js/form-controls.component';
import { dcFactory } from '@deleteagency/dc';

// @ts-ignore
dcFactory.register(FormRangeComponent);

// @ts-ignore
dcFactory.register(FormControlsComponent);
