import { createElement } from 'react';
import { render } from 'react-dom';
import { DcBaseComponent } from '@deleteagency/dc';
import { IDCRefsCollection } from '@deleteagency/dc/src/dc-dom';
import { renderContainer } from 'components/modal/js/render-container';
import { IBurgerLink } from 'components/burger-menu/js/types';
import { BurgerMenu } from 'components/burger-menu/js/burger-menu';

export interface IOptions {
    items?: IBurgerLink[];
    sharedLink?: string;
}

interface IRefs extends IDCRefsCollection {
    btn: HTMLElement;
}

class BurgerMenuComponent extends DcBaseComponent<HTMLDivElement, IOptions, IRefs> {
    static namespace = 'burger-menu';
    static requiredRefs = ['btn'];

    init() {
        this.addListener(this.refs.btn, 'click', this.onClick);
    }

    onClick = () => {
        if (this.options.items) {
            render(
                createElement(
                    BurgerMenu,
                    { items: this.options.items, sharedLink: this.options.sharedLink },
                    null
                ),
                renderContainer
            );
        } else {
            console.warn('items for burger menu has not been specified');
        }
    };
}

export default BurgerMenuComponent;
