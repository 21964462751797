import React from 'react';
import cn from 'classnames';
import { ISvgWrapper } from 'general/types/icon-types';

interface ISvgIcon extends ISvgWrapper {
    iconName?: string;
}

export const SvgIcon = ({
    size,
    className,
    children,
    hasDefaultClass = true,
    iconName,
    ...props
}: ISvgIcon): JSX.Element => (
    <svg
        role="presentation"
        className={cn(className, { icon: hasDefaultClass }) || undefined}
        xmlns="http://www.w3.org/2000/svg"
        width={size ? size[0] : undefined}
        height={size ? size[1] || size[0] : undefined}
        focusable="false"
        {...props}
    >
        {iconName && <use xlinkHref={`#icon-${iconName}`} />}
        {children}
    </svg>
);
