import { DcBaseComponent } from '@deleteagency/dc';

class FormRangeComponent extends DcBaseComponent<HTMLDivElement> {
    static namespace = 'form-range';

    init() {
        const valueBox = this.element.querySelector('.form-range__value');

        if (!valueBox) {
            console.warn('Can not find .form-range__value for form-range');
            return;
        }

        const input: HTMLInputElement = this.element.querySelector(
            '[type="range"]'
        ) as HTMLInputElement;

        const renderValue = () => {
            const value = input.value;
            valueBox.textContent = value;
        };

        this.addListener(input, 'input', renderValue);
        renderValue();
    }
}

export default FormRangeComponent;
