import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { createElement } from 'react';
import { renderContainer } from 'components/modal/js/render-container';
import { SearchModal } from 'components/search/js/search-modal';
import { ISearch } from 'components/search/js/types';

class SearchComponent extends DcBaseComponent<HTMLButtonElement, ISearch, void> {
    static namespace = 'search';

    init() {
        this.addListener(this.element, 'click', this.onClick);
    }

    onClick = () =>
        render(
            createElement(
                SearchModal,
                {
                    endpoint: this.options.endpoint,
                    predictiveSearchEndpoint: this.options.predictiveSearchEndpoint,
                },
                null
            ),
            renderContainer
        );
}

export default SearchComponent;
