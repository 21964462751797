import { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { IPredictiveSearchItem } from 'components/search/js/types';

const PREDICTIVE_SEARCH_DEBOUNCE = 200;

export const usePredictiveSearch = ({
    searchEndpoint,
    predictiveSearchEndpoint,
}: IUsePredictiveSearchOptions): IUsePredictiveSearch => {
    const [items, setItems] = useState([]);
    const status = useRef('idle');
    const abortControllerRef = useRef<null | AbortController>(null);
    let isMounted = true;

    useEffect(() => {
        abortControllerRef.current = new AbortController();

        return () => {
            isMounted = false;
            abortControllerRef.current?.abort();
        };
    }, []);

    const fetchPredictiveSearchItems = useCallback(
        debounce(async (text) => {
            if (!isMounted) return;

            if (status.current === 'loading') {
                abortControllerRef.current?.abort();
                abortControllerRef.current = new AbortController();
            }

            const url = `${predictiveSearchEndpoint}${text}`;

            try {
                status.current = 'loading';
                const response = await axios.post(
                    `${predictiveSearchEndpoint}`,
                    { search: text },
                    {
                        signal: abortControllerRef.current?.signal,
                    }
                );
                const mappedItems = response.data.items.map((item: IPredictiveSearchItem) => ({
                    ...item,
                    link: `${searchEndpoint}?search=${item.text}`,
                }));
                setItems(mappedItems);
                status.current = 'loaded';
            } catch (err) {
                if (!axios.isCancel(err)) {
                    console.warn(`Can't fetch predictive search items, url ${url}`);
                }
            }
        }, PREDICTIVE_SEARCH_DEBOUNCE),
        [setItems]
    );

    return [items, fetchPredictiveSearchItems];
};

type IUsePredictiveSearchOptions = {
    searchEndpoint: string;
    predictiveSearchEndpoint: string;
};

type IUsePredictiveSearch = [IPredictiveSearchItem[], (text: string) => void];
