import { createElement } from 'react';
import { nanoid } from 'nanoid';
import { DcBaseComponent } from '@deleteagency/dc';
import { render } from 'react-dom';
import { AnchorItem, AnchorNav } from 'components/anchor-nav/js/anchor-nav';
import { ANCHOR_DATA_ITEM_ATTR } from 'components/anchor-nav/js/helpers';

class AnchorNavComponent extends DcBaseComponent<HTMLDivElement, void, void> {
    static namespace = 'anchor-nav';

    init() {
        const anchors = [...document.querySelectorAll(`[${ANCHOR_DATA_ITEM_ATTR}]`)].map((item) => {
            const name = item.getAttribute(ANCHOR_DATA_ITEM_ATTR);
            return {
                element: item,
                name,
                id: nanoid(10),
            };
        });

        const caption = this.element.textContent || 'Jump to';

        if (anchors.length > 0) {
            render(
                createElement(AnchorNav, { caption, items: anchors as AnchorItem[] }),
                this.element
            );
        } else {
            this.element.style.display = 'none';
        }
    }
}

export default AnchorNavComponent;
