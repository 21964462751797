import React, { createRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Modal } from 'components/modal/js/modal';
import { unmountModal } from 'components/modal/js/render-container';
import { Container } from 'components/container/js/container';
import { PredictiveSearch } from 'components/search/js/predictive-search';
import { ISearch } from 'components/search/js/types';
import { usePredictiveSearch } from 'components/search/js/usePredictiveSearch';
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/shift-toward.css';

export const SearchModal = ({ endpoint, predictiveSearchEndpoint }: ISearchModal): JSX.Element => {
    const inputRef = createRef<HTMLInputElement>();
    const [value, setValue] = useState('');
    const [predictiveSearchItems, getPredictiveSearchItems] = usePredictiveSearch({
        searchEndpoint: endpoint,
        predictiveSearchEndpoint,
    });

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const onChange = (_value: string) => {
        setValue(_value);
        getPredictiveSearchItems(_value);
    };

    return (
        <Modal closeCb={unmountModal} modifiers={['search']} className="search-modal">
            <Container isGaped>
                <form className="search-modal__form" action={endpoint}>
                    <div className="search-modal__wrapper">
                        <Tippy
                            showOnCreate
                            duration={[250, 100]}
                            placement="bottom-start"
                            maxWidth="100%"
                            animation="shift-toward"
                            interactive={true}
                            interactiveBorder={10}
                            arrow={false}
                            offset={[0, 10]}
                            trigger="click"
                            content={
                                <PredictiveSearch
                                    className="search-modal__predictive-search"
                                    searchLink={endpoint}
                                    items={predictiveSearchItems}
                                />
                            }
                        >
                            <input
                                required
                                className={classnames('search-modal__input', {
                                    'without-text': !value,
                                })}
                                name="search"
                                type="search"
                                placeholder="Start typing"
                                ref={inputRef}
                                onChange={(e) => onChange(e.currentTarget.value)}
                                autoComplete="off"
                            />
                        </Tippy>
                    </div>
                    {!!value.length && (
                        <button className="search-modal__submit" type="submit">
                            Search
                        </button>
                    )}
                </form>
            </Container>
        </Modal>
    );
};

type ISearchModal = ISearch;
