import React, { HTMLAttributes } from 'react';
import { IArticleCard } from 'general/types/api-types';
import { ArticleCard } from 'components/article-card/js/article-card';
import classnames from 'classnames';
import { RemoveBookmarkBtn } from 'components/bookmarks/js/remove-bookmark-btn';

export const ArticleCardsGrid = ({
    items,
    className,
    insideBookmarks,
    ...props
}: IArticleCardsGrid): JSX.Element => {
    return (
        <ul className={classnames(className, 'article-cards-grid')} {...props}>
            {items.map((item) => (
                <li key={item.id}>
                    <ArticleCard {...item} />
                    {insideBookmarks && <RemoveBookmarkBtn title={item.title} id={item.id} />}
                </li>
            ))}
        </ul>
    );
};

interface IArticleCardsGrid extends HTMLAttributes<HTMLUListElement> {
    items: IArticleCard[];
    insideBookmarks?: boolean;
}
